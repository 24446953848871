$background-image: url("https://firebasestorage.googleapis.com/v0/b/titan1-386020.appspot.com/o/header%2Fbackground.svg?alt=media&token=9a330eea-4e81-4b54-b4cb-71aa7c9b5e76");
$font-family: "Lexend", sans-serif;

// Colors, fonts, gradients, shadows
:root {
  --primary-color: #ef0303;
  --black-color: #000000;
  --white-color: #ffffff;

  --lexend-regular: "Lexend Regular", sans-serif;
  --lexend-light: "Lexend Light", sans-serif;
  --lexend-bold: "Lexend Bold", sans-serif;

  // Gradients
  --services-gradient-white: linear-gradient(to bottom, #fff, #ebebeb);
  --services-gradient-red: linear-gradient(to bottom, #ef0303, #a32525);

  // Shadows
  --text-menu-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  --box-menu-shadow: 0px -5px 6px rgba(0, 0, 0, 0.24);
}

body {
  font-family: "Lexend";
}

.header {
  top: 0;
  background-image: $background-image;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
}

h2 {
  font-size: 60px;
  line-height: 0.9;
}

hr {
  margin: 0;
  background: #000;
}

a {
  color: "#fff";
  text-decoration: none;
}

// Meniu

.navbar {
  --bs-nav-link-active-color: var(var(--primary-color));
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--white-color);
  --bs-nav-link-hover-color: var(--primary-color);
  --bs-nav-link-disabled-color: var(--primary-color);
  list-style: none;
}

.navbar-titan1 {
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.navbar-titan1.scrolled {
  background-color: #fff;
  color: "#000";
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.scrolled .nav-link {
  color: #000 !important;
}

@media only screen and (max-width: 992px) {
  .nav-link {
    color: #000;
  }
  h2 {
    font-size: 40px;
  }
}

.scrolled .nav-link:hover {
  color: #ff0000 !important;
}

.scrolled .nav-link.active {
  color: "#ff0000";
}

.navbar-nav .nav-link {
  text-shadow: var(--text-menu-shadow);
  font-size: 18px;
  margin-left: 18px;
  font-family: $font-family;
  font-weight: 400;
}

.nav-link-icon {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  border: 0px;
  box-shadow: var(--box-menu-shadow);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #444;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  font-family: $font-family;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu {
  opacity: 1;
  transform: translateY(0);
}

.nav-link-icon-bg {
  position: absolute;
  top: 3px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  border-radius: 50%;
}

.nav-link-icon-text {
  font-size: 12px;
  font-family: $font-family;
  font-weight: 550;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--primary-color);
}

// Buttons

.btn-primary {
  background-image: var(--services-gradient-red) !important;
  padding: 14px;
  border: none;
  z-index: 99;
}

.btn-primary:hover {
  background-color: #000000 !important;
  padding: 14px;
  border: none;
  transition: 0.5s;
  z-index: 99;
}

.btn-secondary {
  background-image: #444 !important;
  padding: 14px;
  border: none;
  z-index: 99;
}

.btn-secondary:hover {
  background-color: #000000 !important;
  padding: 14px;
  border: none;
  transition: 0.5s;
  z-index: 99;
}

.btn-titan_button {
  background-color: transparent;
  border: 3px solid #fff;
  z-index: 99;
}

.btn-titan_button:hover {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
  color: #fff;
  transition: 0.5s;
  z-index: 99;
}

.btn-titan_button:focus {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
  color: #fff;
  transition: 0.1s;
}

.btn-titan_button:focus-visible {
  color: #fff;
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
  outline: 0;
  box-shadow: var(--primary-color);
}

.btn-titan_button:active {
  color: #fff;
  background-color: var(--primary-color) !important;
  border: 3px solid var(--primary-color) !important;
  outline: 0;
  box-shadow: var(--primary-color);
}

// Services list

.card {
  position: relative;
  background: var(--services-gradient-white);
  border-radius: 20px;
  border: none;
  padding: 10px;
  height: 330px;
  margin: 20px 0 5px 5px;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  position: relative;
  border-top: 4px solid var(--primary-color);
  transform: scale(1.05);
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "transaprent";
}

.card-icon {
  background-color: "#fff";
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
  width: 100px;
  height: 100px;
}

.card-icon:hover {
  fill: red;
}

.card-title {
  font-family: "Lexend";
  font-size: 25px;
  color: #000;
  line-height: 0.9;
}

.card-text {
  font-family: "Lexend";
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  margin-bottom: 20px;
}

.services-circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #ef0303, #a32525);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.extra-div {
  position: absolute;
  top: 0;
  right: -100px;
  width: 100px;
  height: 100px;
  background-color: red;
  float: right;
}

.services-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-circle {
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #ef0303, #a32525);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.service-icon {
  width: 80px;
  height: 80px;
}

.cars {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 200px;
  margin-top: 40px;
}

.services-text {
  display: flex;
  flex-direction: column;
  width: 230px;
}

.image-container {
  background-image: url(../svg/icons/tools.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

// Modals

.img-fixed-size {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.modal-content {
  border-radius: 21px !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  background: var(--services-gradient-red);
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none;
  color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #fff !important;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 1;
}

.btn-close:focus,
.btn-close:focus-within {
  box-shadow: none !important;
}

// Inputs

input:focus {
  border-color: #444 !important;
  color: "#fff";
}

textarea:focus {
  border-color: #444 !important;
  color: "#fff";
}

.form-control:focus,
.form-control:focus-within {
  background-color: "#ff0000";
  box-shadow: none !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 3px;
}

.carousel-indicators li {
  background-color: black; /* spalva */
  border-color: black; /* spalva */
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-indicators .active {
  background-color: var(--primary-color); /* spalva */
  color: var(--primary-color);
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27red%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27red%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e");
}
